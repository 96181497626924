import * as React from 'react'

function PostIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_901_9083)">
        <path
          d="M21.8906 0H2.10938C0.946266 0 0 0.946266 0 2.10938V21.8906C0 23.0537 0.946266 24 2.10938 24H21.8906C23.0537 24 24 23.0537 24 21.8906C24 21.2668 24 2.73473 24 2.10938C24 0.946266 23.0537 0 21.8906 0ZM1.40625 2.10938C1.40625 1.72167 1.72167 1.40625 2.10938 1.40625H21.8906C22.2783 1.40625 22.5938 1.72167 22.5938 2.10938V3.04688H1.40625V2.10938ZM22.5938 21.8906C22.5938 22.2783 22.2783 22.5938 21.8906 22.5938H2.10938C1.72167 22.5938 1.40625 22.2783 1.40625 21.8906V4.45312H22.5938V21.8906Z"
          fill="white"
        />
        <path
          d="M4.875 17.9531H19.125C19.5133 17.9531 19.8281 17.6383 19.8281 17.25V6.75C19.8281 6.36169 19.5133 6.04688 19.125 6.04688H4.875C4.48669 6.04688 4.17188 6.36169 4.17188 6.75V17.25C4.17188 17.6383 4.48669 17.9531 4.875 17.9531ZM5.57812 16.5469V15.3111L9.00895 12.1921L11.537 14.4041C11.8155 14.6478 12.2354 14.6339 12.4972 14.3721L16.0949 10.7744L18.4219 13.3923V16.5469H5.57812ZM18.4219 7.45312V11.2756L16.6505 9.28284C16.3854 8.98458 15.9175 8.96292 15.6278 9.2528L11.968 12.9127L9.46303 10.7208C9.19411 10.4854 8.79145 10.4893 8.52708 10.7296L5.57812 13.4106V7.45312H18.4219Z"
          fill="white"
        />
        <path
          d="M10.875 19.5469H9.375C8.98669 19.5469 8.67188 19.8617 8.67188 20.25C8.67188 20.6383 8.98669 20.9531 9.375 20.9531H10.875C11.2633 20.9531 11.5781 20.6383 11.5781 20.25C11.5781 19.8617 11.2633 19.5469 10.875 19.5469Z"
          fill="white"
        />
        <path
          d="M15.375 19.5469H13.875C13.4867 19.5469 13.1719 19.8617 13.1719 20.25C13.1719 20.6383 13.4867 20.9531 13.875 20.9531H15.375C15.7633 20.9531 16.0781 20.6383 16.0781 20.25C16.0781 19.8617 15.7633 19.5469 15.375 19.5469Z"
          fill="white"
        />
        <path
          d="M5.87784 19.3778L5.62499 19.6306L5.37215 19.3778C5.0976 19.1032 4.65238 19.1032 4.37779 19.3778C4.1032 19.6523 4.1032 20.0975 4.37779 20.3721L5.12779 21.1221C5.40238 21.3967 5.84756 21.3967 6.12215 21.1221L6.87215 20.3721C7.14674 20.0976 7.14674 19.6524 6.87215 19.3778C6.5976 19.1032 6.15238 19.1032 5.87784 19.3778Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_901_9083">
          <rect
            width={24}
            height={24}
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
export default PostIcon
