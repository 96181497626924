import * as React from 'react'

function PromotionIcon(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_177_1781)">
        <path
          d="M7.49031 17.6222C7.75178 17.4714 7.84129 17.1367 7.69047 16.8752C7.53944 16.6139 7.20491 16.5242 6.94343 16.675C6.68196 16.826 6.59224 17.1608 6.74327 17.4221C6.89409 17.6835 7.22883 17.7732 7.49031 17.6222Z"
          fill="white"
          stroke="white"
          strokeWidth={0.4}
        />
        <path
          d="M5.24914 21.3967L8.00317 26.1671C8.59746 27.1976 9.93432 27.5791 10.9915 26.968C12.0376 26.3634 12.3972 25.0268 11.792 23.9798L10.1514 21.1378L11.5722 20.3174C11.8336 20.1662 11.9234 19.8319 11.7723 19.5704L11.0612 18.3387C11.146 18.3222 11.7035 18.2128 21.536 16.284C22.7515 16.218 23.4797 14.8886 22.8658 13.8259L21.0483 10.6779L22.2097 8.91873C22.3238 8.7457 22.3306 8.52331 22.227 8.34387L21.1333 6.44946C21.0297 6.27002 20.8329 6.16492 20.6268 6.17709L18.5228 6.30334L16.5051 2.80847C16.2129 2.30218 15.6893 1.99564 15.1048 1.98837C15.0978 1.98816 15.091 1.98816 15.0839 1.98816C14.5191 1.98816 14.0064 2.26929 13.7026 2.7446L6.55865 11.8454L1.91427 14.5268C0.0875767 15.581 -0.545175 17.9244 0.513114 19.756C1.47997 21.4308 3.51836 22.0897 5.24914 21.3967ZM10.845 24.5269C11.1471 25.0494 10.9683 25.718 10.4442 26.0208C9.91851 26.3247 9.24901 26.138 8.95037 25.6204L6.21599 20.8838L8.1104 19.79C11.275 25.272 10.7941 24.4391 10.845 24.5269ZM9.60448 20.1903L9.05761 19.2431L10.0048 18.6963L10.5517 19.6435L9.60448 20.1903ZM20.3551 7.289L21.1109 8.59808L20.4369 9.61877L19.1342 7.36227L20.3551 7.289ZM14.6174 3.34424C14.7641 3.10413 14.997 3.07935 15.0914 3.08191C15.1847 3.08298 15.4174 3.11203 15.5577 3.35513L21.9186 14.3727C22.1258 14.7314 21.8739 15.1824 21.4584 15.1926C21.3839 15.1943 21.3495 15.2078 21.1585 15.2435L14.43 3.58926C14.5712 3.40768 14.5894 3.39017 14.6174 3.34424ZM13.7022 4.51639L20.0239 15.4661L10.4831 17.3374L7.58211 12.3128L13.7022 4.51639ZM1.46032 19.2092C1.22021 18.7937 1.09331 18.322 1.09331 17.8448C1.09331 16.8694 1.61754 15.961 2.46114 15.474L6.72356 13.013L9.45794 17.7491L5.19552 20.2102C3.88964 20.9639 2.21419 20.5148 1.46032 19.2092Z"
          fill="white"
          stroke="white"
          strokeWidth={0.4}
        />
        <path
          d="M5.79606 17.9689C5.64503 17.7074 5.3105 17.6177 5.04902 17.7687L4.10182 18.3156C3.84077 18.4664 3.5056 18.3765 3.35478 18.1154C3.20375 17.854 2.86922 17.7642 2.60774 17.9153C2.34627 18.0663 2.25654 18.4008 2.40758 18.6623C2.85768 19.442 3.86171 19.717 4.64869 19.2628L5.5959 18.7159C5.85737 18.5649 5.9471 18.2306 5.79606 17.9689Z"
          fill="white"
          stroke="white"
          strokeWidth={0.4}
        />
        <path
          d="M27.1872 3.15799L24.1398 4.85373C23.876 5.00071 23.7809 5.33374 23.9279 5.59757C24.0745 5.86118 24.4073 5.95667 24.6717 5.80948L27.7189 4.11374C27.9829 3.96677 28.0777 3.63373 27.9308 3.36991C27.784 3.10587 27.451 3.01102 27.1872 3.15799Z"
          fill="white"
          stroke="white"
          strokeWidth={0.4}
        />
        <path
          d="M26.8676 8.82201L24.7546 8.2559C24.4628 8.17772 24.1629 8.35075 24.0847 8.64256C24.0065 8.93437 24.1797 9.23408 24.4716 9.31227L26.5847 9.87837C26.8782 9.9572 27.1767 9.78139 27.2544 9.49171C27.3326 9.1999 27.1594 8.89998 26.8676 8.82201Z"
          fill="white"
          stroke="white"
          strokeWidth={0.4}
        />
        <path
          d="M21.5578 1.17107L20.9911 3.28422C20.9129 3.57603 21.0859 3.87575 21.3777 3.95415C21.6693 4.03212 21.9693 3.85951 22.0477 3.56749L22.6142 1.45433C22.6924 1.16252 22.5193 0.862594 22.2275 0.784408C21.9359 0.706222 21.636 0.879256 21.5578 1.17107Z"
          fill="white"
          stroke="white"
          strokeWidth={0.4}
        />
      </g>
      <defs>
        <clipPath id="clip0_177_1781">
          <rect
            width={28}
            height={28}
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
export default PromotionIcon
