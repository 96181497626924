import React from 'react'
import Head from 'next/head'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import MainLayout from '@layouts/MainLayout'
import {
  // HeroSection,
  // MessagingSection,
  // MobileAppSection,
  // NumbersSection,
  PreFooterSection,
  // SystemSection,
  // TestimonialsSection,
  WebsiteSection,
  WhiteLabelSection,
} from '@sections/home/index'
import SetupSection from '@imports/SetupSection'
import { useTranslation, withTranslation } from 'next-i18next'
import HeroSection2 from '@sections/home/HeroSection2'
import AppDeliversSection from '@sections/home/AppDeliversSection'
import MessagingSection2 from '@sections/home/MessagingSection2'
import DashboardSection from '@sections/home/DashboardSection'
// import useExperiment from '@utils/useExperiment'

function Home() {
  // const e = useExperiment('Oo5gXSnlQmGmcvxaGcGRgg', [0, 1, 2])
  const { t } = useTranslation(['meta'])
  return (
    <div>
      <Head>
        <title>{`${t('meta:home.title')} - Blue Assembly`}</title>
        <meta
          name="description"
          content={t('meta:home.description')}
        />
      </Head>
      <MainLayout>
        <HeroSection2 />
        <AppDeliversSection />
        <MessagingSection2 />
        <WhiteLabelSection />
        <DashboardSection />
        <WebsiteSection />
        {/* <NumbersSection />
        <TestimonialsSection /> */}
        <SetupSection />
        <PreFooterSection />
      </MainLayout>
      {/* <MainLayout>
        <HeroSection />
        <SystemSection />
        <WebsiteSection />
        <MobileAppSection />
        <MessagingSection />
        <WhiteLabelSection />
        <NumbersSection />
        <TestimonialsSection />
        <SetupSection />
        <PreFooterSection />
      </MainLayout> */}
    </div>
  )
}

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale)),
  },
})

export default withTranslation(['common'])(Home)
