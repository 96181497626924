import React from 'react'
import { Typography } from '@mui/material'

function UnderlineTypo({ children, color = 'primary.main', sx = {}, ...props }) {
  return (
    <Typography
      display="inline"
      sx={{
        borderWidth: 0,
        borderBottomWidth: 3,
        borderStyle: 'solid',
        pb: 0.5,
        lineHeight: 2,
        borderBottomColor: color,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  )
}

export default UnderlineTypo
