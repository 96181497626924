import React from 'react'
import { Box, Container, Divider, Grid, Typography } from '@mui/material'
import ScrollAnimationRTL from '@components/ScrollAnimationRTL'
import Image from 'next/image'
import { ArrowOutward } from '@mui/icons-material'
import { MessagingSection } from '@sections/home/index'
import { useTranslation } from 'next-i18next'
import GetStartedButton from '@imports/GetStartedButton'
import getLocalizeImage from '@utils/getLocalizedImage'
import Messaging2Mascot from '@assets/mascots/Messaging2Mascot'
import MessagingSM2Mascot from '@assets/mascots/MessagingSM2Mascot'

export function MessagingSection2() {
  const { t, i18n } = useTranslation(['common', 'family_messaging'])
  const scaleX = i18n.dir(i18n.language) === 'rtl' ? -1 : 1

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          display: { xs: 'none', md: 'block' },
          transform: `scaleX(${scaleX})`,
          position: 'absolute',
          right: 0,
          top: -500,
          zIndex: -1,
          opacity: 0.7,
        }}
      >
        <Messaging2Mascot />
      </Box>
      <Box
        sx={{
          display: { xs: 'block', md: 'none' },
          transform: `scaleX(${scaleX})`,
          position: 'absolute',
          right: 0,
          top: -300,
          zIndex: -1,
        }}
      >
        <MessagingSM2Mascot />
      </Box>
      <Box py={12}>
        <Container maxWidth="xl">
          <Typography
            variant="h2"
            sx={{ mb: 4, display: { xs: 'block', md: 'none' } }}
            textAlign="center"
            textTransform="capitalize"
          >
            {t('family_messaging:easy_field_trip_planning')}
          </Typography>
          <Grid
            container
            spacing={5}
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              md={6}
            >
              <ScrollAnimationRTL
                animateOnce
                animateIn="slideInLeft"
              >
                <Image
                  layout="responsive"
                  alt={t('family_messaging:mobile_trip_image')}
                  src={getLocalizeImage('trip.png')}
                  width={1000}
                  height={1000}
                />
              </ScrollAnimationRTL>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <ScrollAnimationRTL
                animateOnce
                animateIn="slideInRight"
              >
                <Grid
                  container
                  spacing={{ xs: 5, md: 8 }}
                  textAlign={{ xs: 'center', md: 'inherit' }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  >
                    <Typography
                      variant="h2"
                      textTransform="capitalize"
                    >
                      {' '}
                      {t('family_messaging:easy_field_trip_planning')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={12}
                  >
                    <Typography
                      variant="h5"
                      sx={{ mb: 3 }}
                      color="primary.main"
                      textTransform="capitalize"
                    >
                      {t('family_messaging:guardian_approvals')}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="neutral.dark"
                    >
                      {t('family_messaging:When_the_school_post_a_new_event_like_a_field_trip')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={12}
                  >
                    <Typography
                      variant="h5"
                      sx={{ mb: 3 }}
                      color="primary.main"
                      textTransform="capitalize"
                    >
                      {t('family_messaging:payments')}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="neutral.dark"
                    >
                      {t('family_messaging:If_payment_is_required_for_a_field_trip')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <GetStartedButton
                      color="secondary"
                      endIcon={<ArrowOutward />}
                      label={t('common:get_started_free')}
                    />
                  </Grid>
                </Grid>
              </ScrollAnimationRTL>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="xl">
        <Divider variant="middle" />
      </Container>
      <MessagingSection />
    </Box>
  )
}

export default MessagingSection2
