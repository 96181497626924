import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'
import Image from 'next/image'
import {
  ArrowForward,
  // ArrowOutward,
  Circle,
} from '@mui/icons-material'
import ScrollAnimationRTL from '@components/ScrollAnimationRTL'
import { useTranslation } from 'next-i18next'
// import GetStartedButton from '@imports/GetStartedButton'
import getLocalizeImage from '@utils/getLocalizedImage'
import DashboardMascot from '@assets/mascots/DashboardMascot'
import DashboardSMMascot from '@assets/mascots/DashboardSMMascot'
import NextLink from '@imports/NextLink'

const items = [
  'manage_registrations',
  'manage_students',
  'calendar_and_events',
  'build_your_school_website',
]
export function DashboardSection() {
  const { t, i18n } = useTranslation(['common', 'dashboard'])
  const scaleX = i18n.dir(i18n.language) === 'rtl' ? -1 : 1

  return (
    <Box sx={{ position: 'relative', zIndex: 1 }}>
      <Box
        py={15}
        sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}
      >
        <Container maxWidth="xl">
          <Grid
            container
            spacing={6}
            alignItems="center"
            justifyContent="center"
            position="relative"
          >
            <Grid
              item
              xs={12}
              md={6}
            >
              <ScrollAnimationRTL
                animateOnce
                animateIn="fadeInLeft"
              >
                <Box>
                  <Typography
                    variant="h4"
                    color="primary.main"
                    sx={{ pb: { xs: 5, md: 8 } }}
                  >
                    {t('dashboard:and_thats_not_all')}
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{ pb: 6 }}
                    textTransform="capitalize"
                    lineHeight={1.2}
                  >
                    {t('dashboard:a_complete_dashboard_to_control_everything')}
                  </Typography>
                  <Grid
                    container
                    spacing={0.5}
                    sx={{ pb: 8 }}
                  >
                    {items.map((item, index) => (
                      <Grid
                        key={index}
                        item
                        xs={12}
                      >
                        <Typography
                          variant="body1"
                          display="block"
                          color="neutral.dark"
                          fontWeight={600}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <Circle
                            sx={{ fontSize: 8 }}
                            color="inherit"
                          />
                          {t(`dashboard:${item}`)}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                  <Typography
                    color="primary.dark"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      transition: (theme) =>
                        theme.transitions.create(['color'], {
                          easing: theme.transitions.easing.sharp,
                          duration: theme.transitions.duration.leavingScreen,
                        }),
                      ':hover': {
                        color: 'secondary.main',
                      },
                    }}
                    textTransform="capitalize"
                  >
                    <NextLink href="/help-center">{t('common:Learn_more')}</NextLink>
                    <ArrowForward fontSize="small" />
                  </Typography>
                </Box>
              </ScrollAnimationRTL>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <ScrollAnimationRTL
                animateOnce
                animateIn="fadeInRight"
              >
                <Image
                  layout="responsive"
                  src={getLocalizeImage('students.png')}
                  alt={t('dashboard:system_dashboard_image')}
                  height={450}
                  width={200}
                />
              </ScrollAnimationRTL>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          display: { xs: 'none', md: 'block' },
          transform: `scaleX(${scaleX})`,
          position: 'absolute',
          right: 0,
          bottom: -712,
          zIndex: -1,
        }}
      >
        <DashboardMascot />
      </Box>
      <Box
        sx={{
          display: { xs: 'block', md: 'none' },
          transform: `scaleX(${scaleX})`,
          position: 'absolute',
          right: 0,
          bottom: -270,
          zIndex: -1,
        }}
      >
        <DashboardSMMascot />
      </Box>
    </Box>
  )
}

export default DashboardSection
