import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'
import Image from 'next/image'
import ScrollAnimationRTL from '@components/ScrollAnimationRTL'
import { useTranslation } from 'next-i18next'

import getLocalizeImage from '@utils/getLocalizedImage'
import UnderlineTypo from '@components/UnderlineTypo'

import PostIcon from '@assets/icons/PostIcon'
import BabyIcon from '@assets/icons/BabyIcon'
import PromotionIcon from '@assets/icons/PromotionIcon'

const features = [
  {
    Icon: PostIcon,
    title: 'sharing_everyday_media',
    description: 'Teachers_and_administrators_can_capture_snapshots',
  },
  {
    Icon: BabyIcon,
    title: 'parents_experience',
    description: 'Parents_can_enjoy_scrolling_the_classroom_feed',
  },
  {
    Icon: PromotionIcon,
    title: 'announcements_made_easy',
    description: 'To_ensure_parents_receive_timely_updates',
  },
]
export function AppDeliversSection() {
  const { t } = useTranslation(['common', 'app_delivers'])
  return (
    <Box>
      <Box
        py={12}
        sx={{
          backgroundColor: 'white',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Container
          maxWidth="xl"
          sx={{ textAlign: { xs: 'center', md: 'inherit' } }}
        >
          <Typography
            variant="h4"
            fontWeight={760000}
            color="primary.main"
            sx={{ mb: 8 }}
            textTransform="capitalize"
          >
            {t('app_delivers:what_the_app_delivers')}
          </Typography>
          <Box mb={12}>
            <UnderlineTypo
              variant="h2"
              fontWeight={700}
              textTransform="capitalize"
            >
              {t('app_delivers:community_through_posting')}
            </UnderlineTypo>
          </Box>
          <ScrollAnimationRTL
            animateOnce
            animateIn="fadeInUp"
          >
            <Grid
              container
              spacing={6}
              alignItems="center"
              justifyContent="center"
              position="relative"
            >
              {features.map(({ Icon, title, description }, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  md={4}
                >
                  <Grid
                    container
                    spacing={4}
                  >
                    <Grid
                      item
                      xs={12}
                      ml={{ xs: 0, md: 1 }}
                    >
                      <Box
                        sx={{
                          backgroundColor: 'primary.main',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: 50,
                          height: 50,
                          transform: 'rotate(30deg)',
                          borderRadius: 1.25,
                          margin: { xs: 'auto', md: 'unset' },
                          // ml: 1,
                        }}
                      >
                        <Icon style={{ transform: 'rotate(-30deg)' }} />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography
                        variant="h5"
                        gutterBottom
                        textTransform="capitalize"
                      >
                        {t(`app_delivers:features.${title}`)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography
                        color="neutral.dark"
                        variant="body2"
                      >
                        {t(`app_delivers:features.${description}`)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </ScrollAnimationRTL>
        </Container>
      </Box>
      <Box
        py={12}
        sx={{
          backgroundColor: 'white',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Container
          maxWidth="xl"
          sx={{ textAlign: { xs: 'center', md: 'inherit' } }}
        >
          <Box
            mb={12}
            sx={{ textAlign: { xs: 'center', md: 'right' } }}
          >
            <UnderlineTypo
              variant="h2"
              fontWeight={700}
              textTransform="capitalize"
            >
              {t('app_delivers:receive_payments_from_parents')}
            </UnderlineTypo>
          </Box>
          <Grid
            container
            spacing={4}
            justifyContent="center"
          >
            <Grid
              item
              xs={12}
              md={5}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Typography
                    variant="h4"
                    textTransform="capitalize"
                    color="primary.main"
                  >
                    {t('app_delivers:payments.parents_pay')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Typography
                    variant="body2"
                    color="neutral.dark"
                  >
                    {t('app_delivers:payments.They_can_pay_tuition_fees_for_the_academic_year')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ textAlign: 'center' }}
                >
                  <Box sx={{ maxWidth: 425, width: '65%', margin: 'auto' }}>
                    <Image
                      layout="responsive"
                      style={{ maxWidth: 425 }}
                      src={getLocalizeImage('mobile-parent-payment.png')}
                      alt={t('app_delivers:payments.parents_payment_screen')}
                      width={1000}
                      height={1000}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
            >
              <Grid
                container
                spacing={4}
                sx={{ pt: { xs: 0, md: 30 } }}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Typography
                    variant="h4"
                    textTransform="capitalize"
                    color="primary.main"
                  >
                    {t('app_delivers:payments.you_collect_&_track')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Typography
                    variant="body2"
                    color="neutral.dark"
                  >
                    {t('app_delivers:payments.Receive_payments,_track_income_and_expenses')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Image
                    layout="responsive"
                    src={getLocalizeImage('dashboard-financial.png')}
                    alt={t('app_delivers:payments.school_finance_dashboard')}
                    width={1000}
                    height={1000}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default AppDeliversSection
