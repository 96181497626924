import React from 'react'
import { Box, Container, Grid, IconButton, Typography } from '@mui/material'
// import Kidstent from '@assets/partners/kidstent.png'
// import Kidsclub from '@assets/partners/kidsclub.png'
// import Playgroup from '@assets/partners/playgroup.png'
// import LittleStars from '@assets/partners/little-stars.png'
import Image from 'next/image'
import {
  ArrowOutward,
  // PlayCircleOutline,
} from '@mui/icons-material'
import ScrollAnimationRTL from '@components/ScrollAnimationRTL'
import { useTranslation } from 'next-i18next'
import GetStartedButton from '@imports/GetStartedButton'
import getLocalizeImage from '@utils/getLocalizedImage'
// import ResponsiveButton from '@components/ResponsiveButton'
import HeroMascot from '@assets/mascots/HeroMascot'
import HeroSMMascot from '@assets/mascots/HeroSMMascot'
// import NextLink from '@imports/NextLink'
import AppStoreSvg from '@assets/icons/AppStoreSvg'
import PlayStoreSvg from '@assets/icons/PlayStoreSvg'

// const partnersLogos = [Kidstent, Kidsclub, Playgroup, LittleStars]

export function HeroSection2() {
  const { t, i18n } = useTranslation(['common', 'hero'])
  const scaleX = i18n.dir(i18n.language) === 'rtl' ? -1 : 1
  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        py={15}
        sx={{ py: { xs: 7, md: 15 } }}
      >
        <Container maxWidth="xl">
          <Grid
            container
            spacing={8}
          >
            <Grid
              item
              xs={12}
              md={7}
            >
              <ScrollAnimationRTL
                animateOnce
                animateIn="fadeIn"
              >
                <Typography
                  variant="h1"
                  align="inherit"
                  fontFamily={i18n.language === 'ar' ? 'inherit' : 'var(--lexend-deca-font)'}
                  fontWeight={300}
                  lineHeight={1.5}
                  textTransform="capitalize"
                >
                  {i18n.language === 'en' && 'The '}
                  <Typography
                    fontWeight={600}
                    fontFamily={i18n.language === 'ar' ? 'inherit' : 'var(--lexend-deca-font)'}
                    variant="h1"
                    component="span"
                  >
                    {t('hero:all-in-one_app')}
                  </Typography>{' '}
                  {t('hero:for_school_communication')}
                </Typography>
              </ScrollAnimationRTL>
              <ScrollAnimationRTL
                animateOnce
                animateIn="fadeIn"
                delay={500}
              >
                <Box
                  mt={8}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                  maxWidth={750}
                >
                  <Typography
                    variant="subtitle2"
                    align="inherit"
                    fontWeight={600}
                    color="neutral.dark"
                  >
                    {t('hero:Connect_with_parents_through_mobile_app')}
                  </Typography>
                </Box>
              </ScrollAnimationRTL>
              <ScrollAnimationRTL
                animateOnce
                animateIn="fadeIn"
                delay={1000}
              >
                <Box pt={6}>
                  <Grid
                    container
                    spacing={3}
                    sx={{ justifyContent: { xs: 'center', md: 'flex-start' } }}
                    alignItems="center"
                  >
                    <Grid item>
                      <GetStartedButton
                        color="secondary"
                        endIcon={<ArrowOutward />}
                        label={t('common:get_started_free')}
                      />
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        spacing={1}
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography
                            color="primary.dark"
                            variant="body2"
                            fontWeight={600}
                          >
                            {t('hero:Available_on')}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                            component="a"
                            href="https://play.google.com/store/apps/details?id=com.blueassembly"
                            target="_blank"
                            aria-label="google play"
                          >
                            <PlayStoreSvg fill="#254675" />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <Box
                            sx={{
                              width: 2,
                              height: 24,
                              borderRadius: 4,
                              backgroundColor: 'primary.dark',
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <IconButton
                            component="a"
                            href="https://apps.apple.com/eg/app/blue-assembly/id1591786196"
                            target="_blank"
                            aria-label="app store"
                          >
                            <AppStoreSvg fill="#254675" />
                          </IconButton>
                        </Grid>
                      </Grid>

                      {/* <Typography
                        color="primary.dark"
                        component="h1"
                        // fontFamily="var(--cairo-font)"
                        variant="subtitle2"
                        fontWeight={600}
                        sx={{
                          my: 2,
                          ml: 2,
                          transition: (theme) =>
                            theme.transitions.create(['color'], {
                              easing: theme.transitions.easing.sharp,
                              duration: theme.transitions.duration.leavingScreen,
                            }),
                          ':hover': {
                            color: 'secondary.main',
                          },
                        }}
                      >
                        <NextLink
                          href="https://meetings-eu1.hubspot.com/fatimah"
                          target="_blank"
                          scroll={false}
                          aria-label={t('common:Request_a_Demo')}
                          style={{ textDecoration: 'underline' }}
                        >
                          {t('common:Request_a_Demo')}
                        </NextLink>
                      </Typography> */}
                      {/* <ResponsiveButton
                        endIcon={<PlayCircleOutline />}
                        sx={{
                          textDecoration: 'underline',
                          ':hover': { textDecoration: 'underline' },
                        }}
                        aria-label={t('hero:watch_demo')}
                      >
                        {t('hero:watch_demo')}
                      </ResponsiveButton> */}
                    </Grid>
                  </Grid>
                </Box>
              </ScrollAnimationRTL>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              sx={{ position: 'relative' }}
            >
              <Box
                sx={{
                  position: { xs: 'relative', md: 'absolute' },
                  top: { xs: 'unset', md: -50 },
                  width: { xs: 'auto', md: '120%' },
                  zIndex: 1,
                }}
              >
                <Image
                  priority
                  layout="responsive"
                  src={getLocalizeImage('mobile-app.png')}
                  alt={t('hero:school_communication_mobile_app')}
                  width={200}
                  height={200}
                />
              </Box>
            </Grid>
            {/* <Grid
              item
              xs={12}
              md={6}
            >
              <ScrollAnimationRTL
                animateOnce
                animateIn="fadeIn"
                delay={1000}
              >
                <Box pt={5}>
                  <Grid
                    container
                    spacing={3}
                    sx={{ justifyContent: { xs: 'center', md: 'flex-start' } }}
                    alignItems="center"
                  >
                    {partnersLogos.map((partnerLogo, index) => (
                      <Grid
                        item
                        key={index}
                      >
                        <Image
                          alt={t('hero:school_logo')}
                          src={partnerLogo}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </ScrollAnimationRTL>
            </Grid> */}
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          display: { xs: 'none', md: 'block' },
          transform: `scaleX(${scaleX})`,
          position: 'absolute',
          right: 0,
          top: -88,
          zIndex: -1,
          opacity: 0.5,
        }}
      >
        <HeroMascot />
      </Box>
      <Box
        sx={{
          display: { xs: 'block', md: 'none' },
          transform: `scaleX(${scaleX})`,
          position: 'absolute',
          right: 0,
          bottom: 250,
          zIndex: -1,
          opacity: 0.8,
        }}
      >
        <HeroSMMascot />
      </Box>
    </Box>
  )
}

export default HeroSection2
